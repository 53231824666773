<template>
  <div class="msglistcont">
    <tojoy-pagination-list schema="block" :data="messageList" :total="total" :size="pagination.size" :currentPage="pagination.page" @page-change="pageChange">
      <template #item="{data}">
        <div class="msglistli" @click="goUrl(data.id, data.toPageType, data.messageTypeName, data.toDetailsInput)" :class="{ msgisread: (msgtype == 0 && data.isRead === 1) || msgtype == 2 }">
          <div class="iconfont" :class="[data.icon, data.class]">
            <span class="hongdian" v-if="data.isRead === 0"></span>
          </div>
          <div class="time">{{ data.sendTimeStr }}</div>
          <div class="type">
            {{ data.messageTypeName }}
            <el-tag style="margin-left:6px;" v-if="msgtype == 2 && data.isDelete" size="mini" type="info">已删除</el-tag>
          </div>
          <div class="title">
            {{ data.title }}
          </div>
          <div class="tips" :class="{ blue: msgtype == 1 }">
            {{ data.content }}
            <img v-if="msgtype == 1" @click.stop="deleteBtn(data.id)" src="@/assets/images/delete.png" />
          </div>
        </div>
      </template>
    </tojoy-pagination-list>
  </div>
</template>

<script>
import { MessageBox, Message } from 'element-ui'
import TojoyPaginationList from '@/components/business/pagination-list'
import { homeallapp } from '@/config/config.js'
import { msgPath } from '@/config/msg.js'
import { msgList, msgRead, delMessage } from '@/api/api_public'
export default {
  name: 'home',
  components: {
    TojoyPaginationList
  },
  data() {
    return {
      homeallapp,
      msgPath,
      total: 0,
      messageList: [], //消息列表
      pagination: {
        page: 1,
        size: 10
      }
    }
  },
  props: {
    msgtype: {
      type: Number,
      default: 1
    }
  },
  mounted() {
    this.getMsgList()
  },
  watch: {
    pagination: {
      handler: function(val, oldval) {
        this.getMsgList()
      },
      deep: true
    },
    msgtype: function(val, oldval) {
      this.pagination.page = 1
      this.getMsgList()
    }
  },
  methods: {
    deleteBtn(id) {
      MessageBox.confirm(`删除功能只是在待办中不显示此任务`, '确定删除吗？', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let params = {
            ids: [id]
          }
          delMessage(params).then(res => {
            if (res.code == '000000') {
              this.getMsgList()
              this.$parent.getMsgCount() //调用父组件清除红点事件
              Message({
                type: 'success',
                message: '删除成功!'
              })
            }
          })
        })
        .catch(() => {
          Message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    pageChange(val) {
      this.pagination.page = val
    },
    goUrl(id, path, name, detailObj) {
      console.log(id, path, name, detailObj)
      let paths = [301, 302, 303, 401, 402, 403]
      this.setMsgRead(id)
      if (path) {
        this.msgPath.forEach(ele => {
          if (ele.toPageType == path) {
            if (paths.includes(path)) {
              sessionStorage.removeItem('code')
              sessionStorage.removeItem('name')
              sessionStorage.setItem('code', ele.code)
              sessionStorage.setItem('name', name)
            }
            if ([501, 502].includes(path)) {
              this.$router.push({
                name: ele.path,
                query: {
                  moduleName: 'review'
                }
              })
            } else {
              this.$router.push({ path: ele.path })
              console.log('1111111', ele.path)
            }
          }
        })
        // path == 204 ? sessionStorage.setItem('batch-view', JSON.stringify(path)) : ''
      }
      //如果进入204待审阅 存个名字和id
      if (detailObj && path) {
        path == 204 ? sessionStorage.setItem('await-Obj', JSON.stringify(detailObj)) : ''
      }
    },
    async setMsgRead(id) {
      let { code, data } = await msgRead({ id })
      if (code === '000000') {
        this.getMsgList()
      }
    },
    getMsgList() {
      let params = {
        page: this.pagination.page,
        size: this.pagination.size,
        type: this.msgtype
      }
      msgList(params).then(result => {
        if (result && result.code === '000000') {
          this.messageList = result.data.list
          console.log('🚀 ~ file: msglistpage.vue:151 ~ msgList ~ this.messageList:', this.messageList)
          this.total = result.data.total
          this.messageList.forEach(e => {
            this.homeallapp.forEach(ele => {
              if (e.messageType === ele.msgtype) {
                e.icon = ele.icon
                e.class = ele.class
              }
            })
          })
        }
      })
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.msglistcont {
  height: 100%;
  overflow: auto;
  .iconbg-yellow {
    background: $yellow;
  }
  .iconbg-green {
    background: $green;
  }
  .iconbg-blue {
    background: $blue;
  }
  .iconbg-red {
    background: $red;
  }
  .msglistli {
    width: 100%;
    padding: 0 0 15px;
    margin-left: 36px;
    position: relative;
    line-height: 26px;
    cursor: pointer;
    .type,
    .time,
    .tips {
      color: $grey;
      font-size: 12px;
    }
    .time {
      position: absolute;
      right: 0;
      top: 5px;
      line-height: 16px;
    }
    .title {
      font-size: 16px;
      line-height: 20px;
      color: $lightblack;
      margin: 4px 0 6px;
    }
    .tips {
      line-height: 16px;
      img {
        display: block;
        width: 16px;
        height: 16px;
        float: right;
      }
    }
    .blue {
      color: $blue;
    }
    .iconfont {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      position: absolute;
      left: -36px;
      top: 0;
      text-align: center;
      color: #fff;
      font-size: 12px;
      .hongdian {
        display: block;
        width: 8px;
        height: 8px;
        background: $red;
        border-radius: 50%;
        position: absolute;
        right: -2px;
        top: -2px;
        border: 1px solid #fff;
      }
    }
  }
  .msgisread {
    .title,
    .tips {
      color: $grey;
    }
    .iconfont {
      background: $grey;
    }
  }
}
</style>
