<template>
  <div class="message-index">
    <tojoy-title-content title="消息">
      <template #footer>
        <div class="tojoy-title-content__footer">
          <tojoy-tabs v-model="tabName" :tabs="tabs" @tab-click="handleTabClick" />
          <div v-if="msgtype == 0" class="readStatu" @click="clickRead">
            <img src="@/assets/images/read.png" />
            <p>全部已读</p>
          </div>
        </div>
      </template>
    </tojoy-title-content>
    <div class="msglistpage">
      <msg-list-page ref="mesList" :msgtype="msgtype"></msg-list-page>
    </div>
  </div>
</template>

<script>
import TojoyTitleContent from '@/components/common/initiate-team/title-content'
import { messageTabs } from '@/config/config'
import MsgListPage from '@/components/message/msglistpage'
import TojoyTabs from '@/components/business/tabs'
import { msgCount, msgAllRead } from '@/api/api_public'
export default {
  name: 'message',
  components: {
    TojoyTitleContent,
    MsgListPage,
    TojoyTabs
  },
  data() {
    return {
      msgtype: 0,
      tabs: messageTabs,
      tabName: messageTabs[0].name
    }
  },
  created() {
    this.getMsgCount()
    this.msgtype = Number(this.$route.query.msgtype || messageTabs[0].type)
    this.tabName = this.tabs.find(e => e.type === this.msgtype).name
  },
  methods: {
    handleTabClick() {
      this.msgtype = this.tabs.find(e => e.name === this.tabName).type
      this.$router.replace({ path: '/message/list', query: { msgtype: this.msgtype } })
    },
    getMsgCount() {
      msgCount().then(result => {
        if (result && result.code === '000000') {
          this.$store.commit('SET_MSG_NUMBER', result.data)
          this.tabs.forEach((e, i) => {
            if (e.type == 0) {
              this.$set(this.tabs[i], 'badge', result.data.unreadCount > 0 ? true : false)
            } else if (e.type == 1) {
              this.$set(this.tabs[i], 'badge', result.data.backlogCount > 0 ? true : false)
            }
          })
        }
      })
    },
    //全部已读
    clickRead() {
      msgAllRead({}).then(res => {
        if (res.code == '000000') {
          this.$refs.mesList.getMsgList()
          this.getMsgCount()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.message-index {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  .msglistpage {
    height: calc(100% - 51px);
  }
  .tojoy-title-content__footer {
    .readStatu {
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px #d9d9d9;
      cursor: pointer;
      border-radius: 2px;
      width: 110px;
      height: 32px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
      p {
        color: #333333;
        font-size: 14px;
      }
    }
  }
}
</style>
